enum WORKLIFE {
  WORKLOAD = "workload",
  CONTROL = "control",
  REWARD = "reward",
  COMMUNITY = "community",
  FAIRNESS = "fairness",
  VALUES = "values",
}

export default WORKLIFE;
