import React from "react";

import { ListAlt } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export type OverviewButtonProps = {
  onClick?: React.MouseEventHandler;
};

function OverviewButton(props: OverviewButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      sx={[
        {
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "transparent",
        },
        {
          "&:hover": {
            borderColor: "primary.main",
          },
        },
      ]}
      variant="text"
      startIcon={<ListAlt />}
      onClick={props.onClick}
    >
      <Typography
        sx={{ fontSize: "0.7rem", fontWeight: "bold", letterSpacing: "0.25em" }}
      >
        {t("common.overview").toUpperCase()}
      </Typography>
    </Button>
  );
}

export default OverviewButton;
