import {
  createTheme,
  ThemeOptions,
  //   responsiveFontSizes,
} from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      light: "#a9a9c2",
      main: "#1F1E5F",
      dark: "#100F44",
    },
    secondary: {
      light: "#f57575",
      main: "#F35C5C",
    },
    background: {
      default: "#F3F3F7",
    },
    grey,
    error: {
      main: "#FFCCCC",
    },
    info: {
      main: "#71F2E3",
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedSecondary: ({ theme }) => ({
          "&:hover": {
            backgroundColor: theme.palette.secondary.light,
          },
        }),
      },
    },
  },
};

const theme = createTheme(themeOptions);

theme.typography.h4 = {
  fontWeight: 400,
  fontSize: "1.325rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
};

theme.typography.h5 = {
  fontWeight: 700,
  fontSize: "1.125rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem",
  },
};

theme.typography.h6 = {
  fontWeight: 500,
  fontSize: "1.125rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem",
  },
};

theme.typography.subtitle1 = {
  fontWeight: 400,
  fontSize: "0.925rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
  lineHeight: 1.75,
  opacity: 0.8,
};

theme.typography.subtitle2 = {
  fontWeight: 500,
  fontSize: "0.925rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
  lineHeight: 1.75,
};

// theme.typography.body1 = {
//   fontFamily: "Poppins",
//   lineHeight: 1.5,
//   fontSize: 15,
//   [theme.breakpoints.up("md")]: {
//     fontSize: 16,
//   },
// };

// theme.typography.button = {
//   fontSize: 15,
//   fontWeight: 700,
//   textTransform: "uppercase",
// };

// A custom theme for this app
// const responsiveTheme = responsiveFontSizes(theme, {
//   breakpoints: ["xs", "md", "lg"],
//   factor: 2,
// });

export default theme;
