import React from "react";

import { Typography } from "@mui/material";

import RATING from "values/rating";
import Colors from "values/colors";
import { useTranslation } from "react-i18next";
import CategoryCardBase from "./CategoryCardBase";

interface CardProps {
  value: number;
  total: number;
  progress: number;
  title: string;
  rating: RATING;
}

export default function CategoryCard({
  value,
  total,
  progress,
  title,
  rating,
}: CardProps) {
  const { t } = useTranslation();
  const percent = Math.round((value / total) * 100);
  const color = Colors[rating];

  const category = title.replace("_", " ");
  const score_value = `${progress}%`;
  const score_translation_key =
    percent > 50 ? "report.score_type.higher" : "report.score_type.lower";

  const text = t(score_translation_key, {
    value: score_value,
    category,
  });

  return (
    <CategoryCardBase
      title={category}
      text={text}
      value={
        <>
          <Typography
            sx={{
              fontSize: ["1.875rem", "2.25rem", "3.25rem"],
              fontWeight: 600,
            }}
          >
            {value.toFixed(1)}
          </Typography>
          <Typography
            sx={{
              fontSize: ["0.875rem", "1rem", "1.25rem"],
              fontWeight: 600,
            }}
          >
            /{total}
          </Typography>
        </>
      }
      percent={percent}
      color={color}
    />
  );
}
