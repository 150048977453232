import React, { useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";

import Answer from "models/Answer";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import type { QuestionTypeProps } from "./QuestionTypeList";

interface AutocompleteProps extends QuestionTypeProps {
  showLabel?: boolean;
}

export default function QuestionTypeAutocomplete({
  question,
  onSelect,
  blinkAnimation,
  showLabel = false,
}: AutocompleteProps) {
  const [selection_made, setSelectionMade] = useState(false);

  const setAnswer = (answer: Answer | null) => {
    if (!answer) return;

    setSelectionMade(true);
    question.setAnswer(answer.value);

    onSelect(question);

    setTimeout(() => {
      setSelectionMade(false);
    }, 300 * 2);
  };

  return (
    <Autocomplete
      sx={{
        animation: selection_made ? blinkAnimation : undefined,
      }}
      openOnFocus
      autoHighlight
      clearOnBlur={false}
      // defaultValue={def_value}
      value={question.answer || null}
      options={question.answers}
      getOptionLabel={(answer) => answer.text}
      onChange={(_, answer) => setAnswer(answer)}
      isOptionEqualToValue={(option, answer) => option.value === answer.value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          hiddenLabel={!showLabel}
          label={showLabel && question.text}
          margin="normal"
          sx={[{ ".MuiInputBase-hiddenLabel": { pb: 1 } }]}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.text, inputValue);
        const parts = parse(option.text, matches);

        return (
          <Box component="li" {...props}>
            <Box
              component="div"
              sx={{
                py: { xs: 1, sm: 1.5 },
                typography: "subtitle2",
              }}
            >
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 600 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </Box>
          </Box>
        );
      }}
    />
  );
}
