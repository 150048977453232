import RATING from "values/rating";
import DIMENSION from "values/dimension";

import { BM_VALUES } from "values/bm";
import PROFILE_TYPE from "values/profile";

import Profile from "./Profile";

export default class Mbi extends Profile<DIMENSION> {
  categories = [
    DIMENSION.EXHAUSTION,
    DIMENSION.CYNICISM,
    DIMENSION.PROFESSIONAL_EFFICACY,
  ];

  values = {
    [DIMENSION.EXHAUSTION]: {
      AVERAGE: 2.16,
      STDEV: 1.46,
      RATING: [10, 69],
    },
    [DIMENSION.CYNICISM]: {
      AVERAGE: 1.41,
      STDEV: 1.16,
      RATING: [10, 69],
    },
    [DIMENSION.PROFESSIONAL_EFFICACY]: {
      AVERAGE: 4.19,
      STDEV: 1.05,
      RATING: [10, 69],
    },
  };

  getCategories(): DIMENSION[] {
    return this.categories;
  }

  calculateRating(mean: number, category: DIMENSION): RATING {
    // const progress = this.getMeanValue(category);
    const progress = this.calculateZProgress(mean, category);

    const [below, above] = this.getBMValues(category).RATING;

    if (progress <= below) return RATING.BAD;
    if (progress <= above) return RATING.AVERAGE;

    return RATING.GOOD;
  }

  getType(): PROFILE_TYPE {
    const [z_exhaustion, z_cynicism, z_efficacy] = this.getZValues();

    if (z_exhaustion < 0.51 && z_cynicism < 1.26 && z_efficacy > 0.1)
      return PROFILE_TYPE.ENGAGED;
    if (z_exhaustion < 0.51 && z_cynicism < 1.26 && z_efficacy < 0.11)
      return PROFILE_TYPE.INEFFECTIVE;
    if (z_exhaustion < 0.51 && z_cynicism > 1.25)
      return PROFILE_TYPE.DISENGAGED;
    if (z_exhaustion > 0.5 && z_cynicism < 1.26)
      return PROFILE_TYPE.OVEREXTENDED;
    if (z_exhaustion > 0.5 && z_cynicism > 1.25) return PROFILE_TYPE.BURNOUT;

    return PROFILE_TYPE.ERROR;
  }

  getRating(): RATING {
    const type = this.getType();

    switch (type) {
      case PROFILE_TYPE.BURNOUT:
        return RATING.BAD;
      case PROFILE_TYPE.ENGAGED:
        return RATING.GOOD;
      default:
        return RATING.AVERAGE;
    }
  }

  getBMValues(category: DIMENSION): BM_VALUES {
    return this.values[category];
  }

  /**
   * Calculate Z Progress.
   * Note that some dimensions required inverted percent values.
   *
   * @param mean
   * @param category
   * @returns
   */
  calculateZProgress(mean: number, category: DIMENSION): number {
    const percent = super.calculateZProgress(mean, category);

    switch (category) {
      case DIMENSION.EXHAUSTION:
      case DIMENSION.CYNICISM:
        return 100 - percent;
      default:
        return percent;
    }
  }
}
