const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
} as Record<Intl.RelativeTimeFormatUnit, number>;

export default function formatDistanceToNow(d1: Date, locale: string): string {
  const elapsed = d1.getTime() - Date.now();
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });

  // "Math.abs" accounts for both "past" & "future" scenarios
  const item = Object.entries(units).find(([_unit, _value]) => {
    return Math.abs(elapsed) > _value || _unit === "second";
  });

  // Item will never be null since "second" will always return
  if (!item) return "";

  const [unit, value] = item;

  return rtf.format(
    Math.round(elapsed / value),
    unit as Intl.RelativeTimeFormatUnit
  );
}
