import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Container,
  Portal,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";

import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as CircleWavesIcon } from "assets/circle-quarter.svg";

import useStepper from "hooks/useStepper";

import Mbi from "models/Mbi";

import Profile from "components/Profile";
import CurrentSession from "components/welcome/CurrentSession";
import CreateReportDialog from "components/questions/CreateReportDialog";

import { updateUser, getUser, SurveyProfileSchema } from "database/firestore";
import {
  calculateMeanValues,
  getAssessments,
  prepareAssessment,
  ProfileData,
} from "database/profileBuilder";
import { i18n } from "i18next";
import { useTranslation } from "react-i18next";
import Aws from "models/Aws";
import RATING from "values/rating";
import IntroductionSlides from "./welcome/IntroductionSlides";

function translateIfExists(i18next: i18n, text: string) {
  return i18next.exists(text) ? i18next.t(text) : text;
}

function translateAssessmentSchema(i18next: i18n, schema: SurveyProfileSchema) {
  const translated_schema = { ...schema };
  const { introduction_key } = schema;

  if (i18next.exists(introduction_key)) {
    translated_schema.introduction = i18next.t(introduction_key, {
      returnObjects: true,
    });
  }

  translated_schema.questions = schema.questions.map((q) => {
    let answers;

    const key = q.text;
    const text = translateIfExists(i18next, key);

    if (q.answers) {
      answers = q.answers?.map((a) => ({
        ...a,
        key: a.text,
        text: translateIfExists(i18next, a.text),
      }));
    }

    return {
      ...q,
      key,
      text,
      answers,
    };
  });

  return translated_schema;
}

/**
 *
 * @todo It would be better to use the Swiper component for all sliding items, in order to avoid
 *          complexity from the useStepper hook. It will probably improve performance
 * @todo change the way missing questions is checked. Now the create-report-dialog is implemented
 *          in two components and goes against single responsibility principle
 * @todo Overview of questions allows only to navigate within the current profile. Is that bad?
 *
 * @returns
 */
export default function Survey() {
  const navigate = useNavigate();
  const { t, i18n: i18next } = useTranslation();

  // The survey assessments we are going to load
  // Defined as comma-separated Firebase document addresses
  const assessments_path =
    process.env.REACT_APP_FIREBASE_SURVEY_PROFILES?.split(",") || [];

  const [assessments, setAssessments] = useState<ProfileData[]>();
  const [snackbar, setSnackbarState] = useState(false);
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(false);
  const [isOpenCreateReportDialog, setOpenCreateReportDialog] = useState(false);

  const { activeStep, stepForward, stepBack, displayStep } = useStepper(
    0,
    // The first two steps are the "introduction" and "cookies consent" slides
    // [introduction, cookies, ...assessments]
    2 + assessments_path.length,
    // Open the report dialog when finished
    { onDone: () => setOpenCreateReportDialog(true) }
  );

  useEffect(() => {
    if (!hasAcceptedCookies || !!assessments) return;

    // Get the assessments from firebase
    getAssessments(assessments_path)
      .then((schemas) =>
        // Assessments can be key based translations, so try to translate the assessment schema
        // If the assessment isn't a translatable key (not existent or simple text), the given string will be used
        schemas.map((schema) => translateAssessmentSchema(i18next, schema))
      )
      // Prepare the assessment by creating necessary object and filling existing answers
      .then((schemas) => schemas.map((s) => prepareAssessment(s)))
      // Set local state
      .then(setAssessments);
  }, [hasAcceptedCookies]);

  const acceptCookiesAndStart = () => {
    setHasAcceptedCookies(true);

    stepForward();
  };

  const navigateToReport = async () => {
    // When navigating to report we expect that the user
    // has filled all required questions
    const mean_values = calculateMeanValues(assessments || []);

    const aws_assessment = new Aws({ mean_values });
    const mbi_assessment = new Mbi({ mean_values });

    const ratings: Record<string, RATING> = {};
    const percentile: Record<string, number> = {};

    aws_assessment.getCategories().forEach((category) => {
      ratings[category] = aws_assessment.getCategoryRating(category);
      percentile[category] = aws_assessment.getCategoryProgress(category);
    });

    mbi_assessment.getCategories().forEach((category) => {
      ratings[category] = mbi_assessment.getCategoryRating(category);
      percentile[category] = mbi_assessment.getCategoryProgress(category);
    });

    updateUser({
      mean_values,
      ratings,
      percentile,
      profile: mbi_assessment.getType(),
    })
      .then(() => {
        return getUser();
      })
      .then((user) => navigate(`/report/${user.uid}`));

    setTimeout(() => {
      setSnackbarState(true);
    }, 7000);

    // const user_id = (await getUser()).uid;
    // navigate(`/report/${user_id}`);
  };

  return (
    <Container
      sx={{
        height: "100%",
        px: "0!important",

        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Snackbar
        open={snackbar}
        onClose={() => setSnackbarState(false)}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert
          elevation={6}
          severity="info"
          variant="filled"
          sx={{
            width: "100%",
            color: "primary.main",
          }}
          icon={
            <CircularProgress
              size={18}
              thickness={4}
              variant="indeterminate"
              color="primary"
            />
          }
        >
          {t("errors.longer_than_usual")}
        </Alert>
      </Snackbar>

      <IntroductionSlides
        sx={{ ...displayStep(0) }}
        onBack={stepBack}
        onDone={acceptCookiesAndStart}
      />
      {/* CurrentSession will create a connection to firebase, so we need to ensure
            that cookies have been accepted */}
      {hasAcceptedCookies && (
        <CurrentSession
          assessments={assessments}
          sx={{ ...displayStep(1) }}
          onDone={stepForward}
        />
      )}
      {assessments && (
        <>
          {/* There can be an unlimited number of assessments */}
          {assessments.map((assessment, index) => (
            <Profile
              key={index}
              profile={assessment}
              sx={{ ...displayStep(index + 2) }}
              onBack={stepBack}
              onDone={stepForward}
            />
          ))}
          <CreateReportDialog
            open={isOpenCreateReportDialog}
            isFinished
            onAccept={navigateToReport}
            onClose={() => setOpenCreateReportDialog(false)}
          />
        </>
      )}

      {/* Render in body so that there is no bottom space on mobile Safari */}
      <Portal container={document.body}>
        <Box
          sx={{
            color: "primary.main",
            opacity: 0.05,
            position: "fixed",
            zIndex: -1,
            bottom: -8,
            right: 0,
            // height: '500px',
            maxHeight: "80vh",
            width: "500px",
            maxWidth: "80%",
            display: "flex",
            alignItems: "flex-end",
            aspectRatio: "1",
          }}
        >
          <CircleWavesIcon width="100%" />
        </Box>
      </Portal>

      {/* Show the logo only on the welcome and cookie slides */}
      {activeStep < 2 && (
        <Portal container={document.body}>
          {/* Render in body so that there is no bottom space on mobile Safari */}
          <Box
            sx={{
              width: { xs: 120, md: 140 },
              position: "absolute",
              left: { xs: 24, md: 32 },
              top: { xs: 24, md: 32 },
              zIndex: -1,
            }}
          >
            <Logo />
          </Box>
        </Portal>
      )}
    </Container>
  );
}
