import React, { useState } from "react";

type Stepper = {
  activeStep: number;
  displayStep: (step: number) => object;
  stepBack: () => void;
  stepForward: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  //   isLastStep: () => boolean;
};

export interface StepperProps {
  onBack?: () => void;
  onDone: () => void;
}

export default function useStepper(
  initialStep: number,
  stepCount: number,
  actions: StepperProps
): Stepper {
  const [activeStep, setStep] = useState<number>(initialStep);

  const stepBack = () => {
    if (activeStep === 0) return actions.onBack && actions.onBack();

    return setStep((step) => step - 1);
  };

  const stepForward = () => {
    if (activeStep === stepCount - 1) return actions.onDone && actions.onDone();

    return setStep((step) => step + 1);
  };

  const displayStep = (step: number) => {
    return activeStep !== step ? { display: "none" } : {};
  };

  //   const isLastStep = () => activeStep === stepCount - 1;

  return {
    activeStep,
    displayStep,
    stepBack,
    stepForward,
    setStep,
    // isLastStep,
  };
}
