import { merge } from "lodash-es";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  doc,
  getDoc,
  setDoc,
  Timestamp,
  Firestore,
  getFirestore,
  DocumentData,
  serverTimestamp,
  PartialWithFieldValue,
  deleteField,
  //   deleteField,
} from "firebase/firestore";

// @todo is it better to handle offline on our own? Firebase lite does not work offline and fails the promise on disconnect
//
// } from "firebase/firestore/lite";

import { Auth, User, getAuth, signOut, signInAnonymously } from "firebase/auth";
import { addDays, endOfDay, differenceInMilliseconds, isAfter } from "date-fns";

import firebaseConfig from "config/firebaseConfig";
import QuestionBase from "models/QuestionBase";

import UAParser from "ua-parser-js";
import { exists, getFixedT } from "i18next";
import { getAnswers } from "models/QuestionList";
import i18n from "../i18n/config";

export type SurveyProfileSchemaIntroduction = {
  confirmation: string;
  text: string;
  title: string;
};

export type SurveyProfileSchemaQuestion = {
  id: string;
  type: "single-select" | "deep-select" | "deep-select-item" | "text";
  key: string;
  text: string;
  answers?: Array<{
    text: string;
    value: string;
    question_id?: string;
  }>;
  category?: string;
  answer?: string;
  clarification?: string;
};

export type SurveyProfileSchema = {
  id: string;
  name: string;
  introduction: Array<SurveyProfileSchemaIntroduction>;
  introduction_key: string;
  questions: Array<SurveyProfileSchemaQuestion>;
};

export type UserDataSchema = {
  surveys: Record<
    string,
    {
      id: string;
      name: string;
      total_questions: number;
    }
  >;
  answers: Record<string, string>;
  mean_values: Record<string, number>;
  invitation_code: string;
  family_invitation_code: string;
  created_at: Timestamp;
  locale: string;
};

let auth: Auth;
let app: FirebaseApp;
let firestore: Firestore;

// let user: User | undefined;
let timer_signout: number | undefined;
let login_promise: Promise<User> | undefined;

const SURVEY_PATH = "surveys";
const RESPONSE_PATH = "survey_response";

function init() {
  app = initializeApp(firebaseConfig);
  firestore = getFirestore(app);
  auth = getAuth();
}

export async function removeUser() {
  //   user = undefined;
  login_promise = undefined;
  clearTimeout(timer_signout);
  timer_signout = undefined;

  await signOut(auth);
}

async function initializeUserResponse() {
  const userResponseRef = doc(firestore, RESPONSE_PATH, auth.currentUser!.uid);
  const userData = await getDoc(userResponseRef);
  if (userData.exists()) return;

  const ua_parser = new UAParser();
  const agent = JSON.parse(JSON.stringify(ua_parser.getResult()));
  // Extend the UA info
  agent.language = navigator.language;

  try {
    await setDoc(userResponseRef, {
      created_at: serverTimestamp(),
      answers: [],
      surveys: [],
      agent,
      locale: i18n.language,
    });
  } catch (error) {
    // User already initialized. Silently ignore
  }
}

/**
 * Login the client to firebase anonymously.
 * This can only be fired once, after the client has been successfully
 * authenticated, this method will return the same resolved Promise
 *
 * @returns
 */
export async function loginAnonymously() {
  if (!app) init();

  // Be sure to try to login only once.
  // Avoids settings the sign-out timer twice and
  if (login_promise) return login_promise;

  login_promise = new Promise((resolve, reject) => {
    signInAnonymously(auth)
      .then(async ({ user }) => {
        const today = new Date();
        const created_at = new Date(user.metadata.creationTime as string);
        const end_of_next_day = endOfDay(addDays(created_at, 1));

        if (isAfter(today, end_of_next_day)) {
          await removeUser();

          resolve(loginAnonymously());

          return;
        }

        // Don't set the timer twice. If the user is removed, then the timer is set to undefined
        if (!timer_signout) {
          // The timer will end at the end of the next day and will reload without notice.
          // We assume that no user will need to re-visit the report/survey at this point.
          // If necessary we can implement a second shorter timeout, e.g. end_of_day - 10 minutes, and show a countdown.
          timer_signout = window.setTimeout(() => {
            // Is this necessary? On reload, the date verification will run again
            // so we can assume the user will be removed on load
            // removeUser();

            window.location.reload();
          }, differenceInMilliseconds(end_of_next_day, today));
        }

        await initializeUserResponse();

        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        resolve(user);
      })
      .catch((error) => {
        reject(login_promise);
        login_promise = undefined;
        //   const errorCode = error.code;
        const errorMessage = error.message;

        console.error("Could not create secure connection", errorMessage);
      });
  });

  return login_promise;
}

/**
 * Helper function to update any of the user's input in firebase
 * It will automatically update timestamps
 *
 * @param data any `object` type primitives that should be persisted
 * @returns A `Promise` resolved once the data has been successfully written to the backend.
 */
export async function updateUser(data: PartialWithFieldValue<DocumentData>) {
  //   await loginAnonymously();

  const options = { merge: true };

  const reference = doc(firestore, RESPONSE_PATH, auth.currentUser!.uid);

  return setDoc(
    reference,
    {
      ...data,
      updated_at: serverTimestamp(),
    },
    options
  );
}

/**
 * Get the survey data merged with the user's answers from Firebase
 *
 * @param path the document path in Firebase
 * @returns a typed JSON structure with the expected data
 */
export async function getSurvey(
  path: string,
  user_data: UserDataSchema
): Promise<SurveyProfileSchema> {
  await loginAnonymously();

  const pathSegments = path.split("/");

  // Get the surveys
  const surveyRef = doc(firestore, SURVEY_PATH, ...pathSegments);
  const surveySnapshot = await getDoc(surveyRef);
  const data = surveySnapshot.data() as SurveyProfileSchema;

  // If the id of the survey is not on the user data survey list
  if (!(data?.id in user_data.surveys)) {
    console.log(data.questions);
    // For a better overview, save which surveys the user will be answering
    const survey_data = {
      id: data.id,
      name: data.name,
      //   total_questions: data.questions.length,
      total_questions: data.questions.filter(
        (q) => q.type !== "deep-select-item"
      ).length,
    };

    await updateUser({
      // Since the user hasn't answered the survey yet, we need to save the survey data
      invitation_code: process.env.REACT_APP_INVITATION_CODE || "",
      invitation_family_code:
        process.env.REACT_APP_INVITATION_FAMILY_CODE || "",
      surveys: {
        [data.id]: survey_data,
      },
    });

    // The survey wasn't on the user data surveys list, so there are no answers
    // exit
    return data;
  }

  // Merge existing user data with the given surveys
  const questions_with_answers = data.questions.map((question) => ({
    ...question,
    answer: user_data.answers[question.id],
  }));

  //   data.total_questions = questions_with_answers.filter(
  //     (q) => q.type !== "deep-select-item"
  //   ).length;

  //   console.log("questions", questions_with_answers, data.total_questions);
  // Merge existing user data with the given surveys
  data.questions = questions_with_answers;

  return data;
}

/**
 * Persist the user's answer to firebase and update timestamps
 *
 * @param question the item being answered
 * @returns the a promised result of the Firebase transaction.
 */
export async function saveQuestion(question: QuestionBase) {
  if (!question.answer) return Promise.resolve(); // It's not an error to have an empty answer
  // return Promise.reject(new Error("Answer may not be empty"));

  const english_text = exists(question.key)
    ? getFixedT("en")(question.key)
    : question.text;

  const data = {
    questions: {
      [question.id]: english_text,
    },
    answers: {
      [question.id]: question.answer?.value,
    },
  };

  if (["deep-select", "deep-select-item"].includes(question.type)) {
    // If the question is a deep-select or deep-select-item, we need to recursively
    // save all the children answers and their respective questions
    Object.entries(getAnswers(question)).forEach(([id, o]) => {
      // Follow the tree structure under the current question
      // If the answer is empty, we need to delete existing keys to avoid saving deleted answers
      if (!o.answer) {
        data.answers = merge(data.answers, { [id]: deleteField() });
        data.questions = merge(data.questions, { [id]: deleteField() });
      } else {
        data.answers[id] = o.answer;
        data.questions[id] = exists(id) ? getFixedT("en")(id) : o.question;
      }
    });
  }

  if (question.id === process.env.REACT_APP_INVITATION_SUFFIX_QUESTION_ID) {
    return updateUser({
      ...data,
      invitation_code:
        `${process.env.REACT_APP_INVITATION_PREFIX}${question.answer?.value}${process.env.REACT_APP_INVITATION_SUFFIX}`.toUpperCase(),
      family_invitation_code:
        `${process.env.REACT_APP_FAMILY_INVITATION_PREFIX}${question.answer?.value}${process.env.REACT_APP_FAMILY_INVITATION_SUFFIX}`.toUpperCase(),
    });
  }

  return updateUser(data);
}

/**
 * Persist the user's mean values to firebase
 *
 * @param mean_values the mean of all question categories
 * @returns
 */
export async function saveMeanValues(mean_values: Record<string, string>) {
  return updateUser({ mean_values });
}

export async function getUser(): Promise<User> {
  //   await loginAnonymously();

  return auth.currentUser!;
}

export async function getUserData() {
  await loginAnonymously();

  const userResponseRef = doc(firestore, RESPONSE_PATH, auth.currentUser!.uid);
  const userSnapshot = await getDoc(userResponseRef);

  return userSnapshot.data() as UserDataSchema;
}

export async function getSurveyResultByUserId(user_id: string) {
  if (!app) init();

  const userResponseRef = doc(firestore, RESPONSE_PATH, user_id);
  const userSnapshot = await getDoc(userResponseRef);

  return userSnapshot.data() as UserDataSchema;
}
