import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LanguageIcon from "@mui/icons-material/Language";
import {
  Grid,
  ButtonProps,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";

import emotion from "assets/welcome-emotion.png";

import NextButton from "components/base/NextButton";
import WelcomeCardContainer, {
  WelcomeCardContent,
} from "components/base/WelcomeCardContainer";
import { supportedLngs } from "i18n/config";

function WelcomeSlide(props: ButtonProps) {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState<string>(i18n.language);

  const is_language_selection_hidden =
    process.env.REACT_APP_LANGUAGE_SELECTION_HIDDEN?.toLowerCase() === "true";

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <WelcomeCardContainer sx={{ ...props.sx }}>
      <Grid container>
        <Grid item xs={12} sm={10} md={10}>
          <WelcomeCardContent
            title={t("slides.welcome.title")}
            subtitle={t("slides.welcome.text")}
          />
        </Grid>
      </Grid>

      <NextButton
        onClick={props.onClick}
        sx={{
          px: "clamp(32px, 18%, 64px)",
          width: "auto",
          fontWeight: 700,
          mb: { xs: 2, md: 0 },
        }}
      >
        {t("confirmation.lets_begin")}
      </NextButton>

      <br />
      {!is_language_selection_hidden && (
        <TextField
          select
          size="small"
          sx={{
            mt: { xs: 8, md: 6 },
            width: 250,
          }}
          label={t("common.choose_language")}
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LanguageIcon sx={{ opacity: 0.6 }} />
              </InputAdornment>
            ),
          }}
        >
          {supportedLngs.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      )}

      {/* <Autocomplete
        disablePortal
        size="small"
        sx={{ pt: 6, width: 200 }}
        value={language}
        options={languages}
        isOptionEqualToValue={(option, lang) => option.value === lang.value}
        onChange={(e, lang) => {
          if (lang) setLanguage(lang);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <LanguageIcon />
            //     </InputAdornment>
            //   ),
            // }}
            label={t("common.choose_language")}
          />
        )}
      /> */}

      <img
        src={emotion}
        alt="Welcome emotion"
        style={{
          position: "absolute",
          pointerEvents: "none",
          bottom: 0,
          right: 0,
          width: "40%",
        }}
      />
    </WelcomeCardContainer>
  );
}

export default WelcomeSlide;
