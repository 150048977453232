import * as React from "react";
import Dialog from "@mui/material/Dialog";

import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";

import { Close } from "@mui/icons-material";

import {
  Avatar,
  Box,
  Divider,
  lighten,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from "@mui/material";

import ContentContainer from "components/base/ContentContainer";
import QuestionBase from "models/QuestionBase";
import CurrentSessionDialog from "components/welcome/CurrentSessionDialog";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface QuestionOverviewDialogProps {
  open: boolean;
  questions: Array<QuestionBase>;
  onClose: () => void;
  onSelection: (index: number) => void;
}

/**
 * Fullscreen dialog containing the overview of all questions and answers
 *
 * @param props
 * @returns
 */
function QuestionOverviewDialog(props: QuestionOverviewDialogProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleSelect = (index: number) => {
    props.onSelection(index);
    props.onClose();
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: { backgroundColor: theme.palette.background.default },
      }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            {t("common.overview")}
          </Typography>
          <CurrentSessionDialog />
        </Toolbar>
      </AppBar>
      <Box sx={{ overflowY: "scroll" }}>
        <ContentContainer sx={{ my: { xs: 0, md: 3 }, p: 0, pt: 0 }}>
          <List
            sx={{ width: "100%", bgcolor: "background.paper", borderRadius: 4 }}
          >
            {props.questions.map((_, index) => (
              <React.Fragment key={_.text}>
                <ListItem
                  sx={{
                    // hover states
                    "&:hover": {
                      bgcolor: lighten(theme.palette.secondary.main, 0.9),
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelect(index)}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: _.isAnswered() ? "secondary.light" : undefined,
                      }}
                    >
                      {index + 1}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={_.text}
                    primaryTypographyProps={{
                      noWrap: true,
                      fontWeight: _.isAnswered() ? "500" : undefined,
                      fontSize: { xs: "0.925rem", md: "1rem" },
                    }}
                    secondary={
                      <Typography
                        noWrap
                        sx={{
                          fontSize: { xs: "0.925rem", md: "1rem" },
                          opacity: _.isAnswered() ? 1 : 0.5,
                          fontStyle: _.isAnswered() ? "none" : "italic",
                        }}
                      >
                        {_.isAnswered() ? _.answer!.text : t("common.required")}
                      </Typography>
                    }
                  />
                </ListItem>
                {index < props.questions.length - 1 && (
                  <Divider variant="inset" component="li" />
                )}
              </React.Fragment>
            ))}
          </List>
        </ContentContainer>
      </Box>
    </Dialog>
  );
}

export default QuestionOverviewDialog;
