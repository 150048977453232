import Answer from "./Answer";
import QuestionBase, { QuestionBaseValues } from "./QuestionBase";

export interface QuestionFreetextValues extends QuestionBaseValues {
  clarification: string;
}

export default class QuestionFreetext
  extends QuestionBase
  implements QuestionFreetextValues
{
  clarification: string;

  constructor(values: QuestionFreetextValues) {
    super(values);

    this.clarification = values.clarification;
  }

  setAnswer(value: string) {
    if (value.trim() === "") {
      this.answer = undefined;
    } else {
      this.answer = new Answer({
        text: value,
        value,
      });
    }

    this.notify();
  }

  isAnswered(): boolean {
    return this.answer !== undefined && this.answer.value.trim() !== "";
  }
}
