import React from "react";

import { Box, Typography } from "@mui/material";
import RATING from "values/rating";
import ColorValues from "values/colors";
import { Circle } from "@mui/icons-material";

interface RatingLegendProps {
  // Ratings text changes depending on profile
  ratings_text: Record<RATING, string>;
}

export default function RatingLegend({ ratings_text }: RatingLegendProps) {
  // Need to ensure the order
  const ratings = [RATING.GOOD, RATING.AVERAGE, RATING.BAD];

  return (
    <Box
      sx={{
        p: 1,
        px: { xs: 2, md: 4 },
        mt: 2,
        bgcolor: "background.default",
        borderRadius: 4,
        display: { xs: "none", sm: "flex" },
        justifyContent: "flex-end",
        alignItems: "flex-end",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      {ratings.map((r) => (
        <Typography
          key={r}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            ml: { xs: 2, md: 3 },
            fontWeight: 500,
            fontSize: "0.95em",
            color: "primary.main",
          }}
        >
          <Circle sx={{ color: ColorValues[r], fontSize: 8 }} />{" "}
          {ratings_text[r]}
        </Typography>
      ))}
    </Box>
  );
}
