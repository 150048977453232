import DIMENSION from "values/dimension";
import WORKLIFE from "values/worklife";

import Answer from "./Answer";
import QuestionBase, { QuestionBaseValues } from "./QuestionBase";

export interface QuestionValues extends QuestionBaseValues {
  category: WORKLIFE | DIMENSION;
  answers: Answer[];
}

export default class Question extends QuestionBase implements QuestionValues {
  category: WORKLIFE | DIMENSION;

  answers: Answer[];

  constructor(values: QuestionValues) {
    super(values);

    this.answers = values.answers;
    this.category = values.category;
  }

  setAnswer(value: string) {
    this.answer = this.answers.find((a) => a.value === value);
  }

  isAnswered(): boolean {
    return this.answer !== undefined;
  }
}
