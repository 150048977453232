import React, { ReactFragment, ReactNode } from "react";

import { alpha, Box, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

interface CardBaseProps {
  title: ReactNode | ReactFragment;
  text: ReactNode | ReactFragment;
  value: ReactNode | ReactFragment;
  percent: number;
  color: string;
}

export default function CategoryCardBase({
  title,
  text,
  color,
  percent,
  value,
}: CardBaseProps) {
  return (
    <Box
      sx={{
        p: { xs: 2, md: 3.5 },
        bgcolor: "background.default",
        overflow: "hidden",
        borderRadius: 4,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          borderRadius: "100%",
          height: 148,
          width: 148,
          backgroundColor: alpha(color, 0.1),
          marginTop: "-148px",
          transform: "translate(-42px, 92px)",
        }}
      >
        <Box
          sx={{
            borderRadius: "100%",
            height: 148,
            width: 148,
            backgroundColor: "inherit",
            transform: "scale(0.72)",
          }}
        >
          <Box
            sx={{
              borderRadius: "100%",
              height: 148,
              width: 148,
              backgroundColor: "inherit",
              transform: "scale(0.6)",
            }}
          />
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: "1.4em",
          fontWeight: 600,
          color: "primary.main",
          textTransform: "capitalize",
          position: "relative",
          mb: { xs: 1, md: 0 },
        }}
      >
        {title}
      </Typography>

      <Box flex={1} />

      <Box
        sx={{
          display: "flex",
          flexDirection: ["row-reverse", null, "column"],
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            mt: [-3, null, 3],
            mb: { xs: 0, md: 1.5 },
            ml: { xs: 2, md: 0 },
            height: [100, 140, 195],
            width: [100, 140, 195],
            alignSelf: { xs: "flex-end", md: "center" },
          }}
        >
          <CircularProgress
            thickness={6}
            size="inherit"
            variant="determinate"
            value={100}
            sx={{ color: grey[300], position: "absolute" }}
          />
          <CircularProgress
            thickness={6}
            size="inherit"
            variant="determinate"
            value={percent}
            sx={{ color }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              color: "primary.main",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              {value}
            </Box>
          </Box>
        </Box>
        <Typography sx={{ fontWeight: 500, color: "primary.dark" }}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
