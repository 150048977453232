import { Box, BoxProps, keyframes, Typography } from "@mui/material";
import React, { FC, ReactNode } from "react";

const enter = keyframes`
    from {
        transform: translate3d(0,16px,0);
        opacity: 0;
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
`;

export function WelcomeCardContent(props: {
  title: ReactNode;
  subtitle: ReactNode;
}) {
  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "primary.main" /* fontSize: 24 */ }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          //   lineHeight: 1.75,
          mt: { xs: 3, md: 4 },
          mb: { xs: 4, md: 6 },
          //   opacity: 0.8,
          whiteSpace: "pre-wrap",
        }}
      >
        {props.subtitle}
        {/* <span dangerouslySetInnerHTML={{ __html: welcome[0].text }} /> */}
      </Typography>
    </>
  );
}

const WelcomeCardContainer: FC<BoxProps> = ({ children, ...props }) => {
  const { sx, ...other } = props;

  return (
    <Box
      sx={{
        mx: { xs: 2, sm: 6 },
        p: { xs: 4, sm: 6, md: 10 },
        borderRadius: 4,
        maxWidth: "md",
        width: "100%",
        position: "relative",
        bgcolor: "background.paper",
        boxShadow: "0px 12px 24px rgba(145, 158, 171, 0.12)",
        animation: `${enter} 0.5s ease`,
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default WelcomeCardContainer;
