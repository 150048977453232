import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Theme,
  Typography,
  SxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Skeleton,
  CircularProgress,
  BoxProps,
} from "@mui/material";

import { addDays, endOfDay } from "date-fns";

import { getUser, removeUser } from "database/firestore";
import { StepperProps } from "hooks/useStepper";

import NextButton from "components/base/NextButton";
import MainContainer from "components/base/MainContainer";
import WelcomeCardContainer, {
  WelcomeCardContent,
} from "components/base/WelcomeCardContainer";
import { Trans, useTranslation } from "react-i18next";
import formatDistanceToNow from "utils/date";
import { ProfileData } from "database/profileBuilder";

interface CurrentSessionProps extends StepperProps {
  sx?: SxProps<Theme>;
  assessments?: ProfileData[];
}

type StateOfAssessment = {
  total_questions: number;
  total_answers: number;
};

function ResetSessionText(props: BoxProps) {
  return (
    <Trans i18nKey="slides.session.text">
      If you wish to restart your survey you can
      <Box
        component={"span"}
        sx={{
          display: "inline",
          textDecoration: "underline",
          ":hover": {
            color: (theme) => theme.palette.error.main,
            cursor: "pointer",
          },
        }}
        onClick={props.onClick}
      >
        clear all responses
      </Box>
    </Trans>
  );
}

function ResetSessionStatus({
  count,
  total,
}: {
  count: number;
  total: number;
}) {
  return (
    <Trans
      i18nKey="slides.session.status"
      components={[<span key="bold" style={{ fontWeight: 500 }} />]}
      values={{
        count,
        total,
      }}
    />
  );
}

function ResetSessionForm({
  id,
  data,
}: {
  id: string;
  data: StateOfAssessment;
}) {
  const { t } = useTranslation();
  const [dialogOpened, setDialogOpen] = useState(false);

  const { total_answers, total_questions } = data;

  if (!total_answers) return <></>;

  return (
    <>
      <br />
      <br />
      <ResetSessionText onClick={() => setDialogOpen(true)} />
      <br />
      <br />
      <ResetSessionStatus count={total_answers} total={total_questions} />
      <br />
      {total_questions === total_answers && (
        <Link
          to={`/report/${id}`}
          sx={{ color: "inherit", textDecorationColor: "inherit" }}
          component={RouterLink}
        >
          {t("slides.session.open_report")}
        </Link>
      )}

      <Dialog
        maxWidth={false}
        open={dialogOpened}
        onClose={() => setDialogOpen(false)}
      >
        <DialogContent>
          <Typography variant="h6">
            {t("slides.session.do_you_wish_to_restart")}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            flexWrap: "wrap",
          }}
        >
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            {t("common.cancel")}
          </Button>
          <Button
            color="error"
            variant="contained"
            disableElevation={true}
            onClick={() => {
              removeUser();
              window.location.reload();
            }}
          >
            {t("slides.session.delete_and_restart")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function SessionContent(props: CurrentSessionProps) {
  const { t, i18n } = useTranslation();
  const [failed, setFailed] = useState(false);
  const [state, setState] = useState<{
    id: string;
    assessments: StateOfAssessment;
    expires_on: string;
  }>();

  useEffect(() => {
    if (!props.assessments) return;

    const initializeSession = async () => {
      if (!props.assessments) return;

      const user = await getUser();

      const created_at = new Date(user.metadata.creationTime as string);
      const end_of_next_day = endOfDay(addDays(created_at, 1));

      const distance_to_now = formatDistanceToNow(
        end_of_next_day,
        i18n.language
      );

      setState({
        id: user.uid,
        // data,
        assessments: props.assessments.reduce(
          (acc, assessment) => {
            acc.total_questions += assessment.questions.length;
            acc.total_answers += assessment.questions.filter(
              (q) => q.answer
            ).length;
            return acc;
          },
          {
            total_questions: 0,
            total_answers: 0,
          } as StateOfAssessment
        ),

        expires_on: distance_to_now,
      });
    };

    initializeSession()
      // Could not connect to firebase
      .catch(() => {
        setFailed(true);
      });
  }, [props.assessments]);

  if (failed) {
    return (
      <>
        <WelcomeCardContent
          title={t("errors.an_error_occurred")}
          subtitle={t("errors.session_uninitialised")}
        />
        <NextButton
          onClick={() => window.location.reload()}
          sx={{
            px: 8,
            width: "auto",
            fontWeight: 700,
          }}
        >
          {t("common.reload")}
        </NextButton>
      </>
    );
  }

  if (state) {
    return (
      <>
        <WelcomeCardContent
          title={t("slides.session.title")}
          subtitle={
            <>
              {t("slides.session.will_expire_on", {
                relative_date: state.expires_on,
              })}
              <ResetSessionForm id={state.id} data={state.assessments} />
            </>
          }
        />

        <NextButton
          onClick={props.onDone}
          sx={{
            px: 8,
            width: "auto",
            fontWeight: 700,
          }}
        >
          {t("confirmation.okay")}
        </NextButton>
      </>
    );
  }

  // Default is loading state
  return (
    <WelcomeCardContent
      title={
        <>
          <CircularProgress size={18} thickness={6} />{" "}
          {t("slides.session.starting")}
        </>
      }
      subtitle={<Skeleton variant="rectangular" height={128} />}
    />
  );
}

export default function CurrentSession(props: CurrentSessionProps) {
  return (
    <>
      <MainContainer sx={{ ...props.sx }}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <WelcomeCardContainer>
            <SessionContent {...props} />
          </WelcomeCardContainer>
        </Box>
      </MainContainer>
    </>
  );
}
