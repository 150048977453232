import React, { useState } from "react";
import {
  Dialog,
  Box,
  DialogContent,
  Typography,
  alpha,
  AppBar,
  IconButton,
  Toolbar,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { ReactComponent as IconRelaxed } from "assets/icon-relaxed.svg";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface CreateReportDialogProps {
  isFinished: boolean;
  open: boolean;
  onAccept: () => void;
  onClose: (event: object, reason?: string) => void;
}

interface ReportDialogContentProps {
  title: string;
  content: string;
  action: string;
  loading?: boolean;
  onClick: () => void;
}

function ReportDialogContent({
  title,
  content,
  action,
  loading,
  onClick,
}: ReportDialogContentProps) {
  return (
    <>
      <Typography
        variant="h4"
        sx={{
          color: "primary.main",
          fontWeight: 500,
          my: 2,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 7,
          textAlign: "center",
          color: (theme) => alpha(theme.palette.primary.main, 0.7),
        }}
      >
        {content}
      </Typography>

      <LoadingButton
        variant="contained"
        color="secondary"
        size="large"
        sx={{
          borderRadius: 2,
          width: "calc(80%)",
        }}
        loading={loading}
        onClick={onClick}
      >
        {action}
      </LoadingButton>
    </>
  );
}

export default function CreateReportDialog({
  isFinished,
  open,
  onClose,
  onAccept,
}: CreateReportDialogProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const content = isFinished ? (
    <ReportDialogContent
      title={t("report.finished.title")}
      content={t("report.finished.description")}
      action={t("report.create")}
      loading={loading}
      onClick={() => {
        setLoading(true);
        onAccept();
      }}
    />
  ) : (
    <ReportDialogContent
      title={t("report.unfinished.title")}
      content={t("report.unfinished.description")}
      action={t("confirmation.take_me_there")}
      onClick={onAccept}
    />
  );

  return (
    <Dialog
      fullWidth
      // disableEscapeKeyDown
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <AppBar
        sx={{
          position: "relative",
          background: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton edge="end" onClick={onClose} aria-label="close">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: "auto",
            width: "fit-content",
            py: { xs: 4, sm: 4, mb: 8, mt: 7 },
            px: { xs: 0, sm: 4, md: 6 },
          }}
        >
          <Box sx={{ width: 72 }}>
            {/* <IconRelaxed /> */}
            <IconRelaxed
              style={{ filter: "drop-shadow(0px 0px 25px rgb(0 0 0 / 15%))" }}
            />
          </Box>
          {content}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
