import React from "react";

import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import SectionContainer from "./base/SectionContainer";

export default function SectionAbout() {
  const { t } = useTranslation();

  const texts = t("report.about.help_points", {
    returnObjects: true,
  }) as string[];

  return (
    <SectionContainer title={t("report.about.title")}>
      <Typography>{t("report.about.description")}</Typography>
      <Typography sx={{ fontWeight: 600, mt: 4, mb: 2, color: "primary.main" }}>
        {t("report.about.it_can_help").toUpperCase()}...
      </Typography>
      <Box sx={{ "> p": { mb: 1 } }}>
        {texts.map((text) => (
          <Typography
            key={text}
            sx={{
              px: 4,
              py: 2,
              borderRadius: 4,
              fontWeight: 500,
              bgcolor: "background.default",
              color: "primary.main",
            }}
          >
            {text}
          </Typography>
        ))}
      </Box>
      <Typography sx={{ fontSize: "0.825em", mt: 3 }}>
        {t("report.about.interpretation_not_diagnostic")}
      </Typography>
    </SectionContainer>
  );
}
