import React from "react";

import IntroSlider from "components/introduction/IntroSlider";
import QuestionSlider from "components/questions/QuestionSlider";

import useStepper, { StepperProps } from "hooks/useStepper";
import { Box, SxProps, Theme } from "@mui/material";
import { ProfileData } from "database/profileBuilder";

interface ProfileProps extends StepperProps {
  sx?: SxProps<Theme>;
  profile: ProfileData;
}

export default function Profile(props: ProfileProps) {
  const { introData, questions } = props.profile;

  const stepCount = introData.length > 0 ? 2 : 1;

  const { stepForward, stepBack, displayStep } = useStepper(
    0,
    stepCount,
    props
  );

  return (
    <Box sx={{ ...props.sx, height: "100%" }}>
      {stepCount > 1 && (
        <IntroSlider
          data={introData}
          sx={{ ...displayStep(0) }}
          onBack={stepBack}
          onDone={stepForward}
        />
      )}

      <QuestionSlider
        questions={questions}
        sx={{ ...displayStep(stepCount > 1 ? 1 : 0) }}
        onBack={stepBack}
        onDone={stepForward}
      />
    </Box>
  );
}
