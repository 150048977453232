import {
  getSurvey,
  getUserData,
  SurveyProfileSchema,
  SurveyProfileSchemaQuestion,
  UserDataSchema,
} from "database/firestore";

import Answer from "models/Answer";
import IntroText from "models/IntroText";
import Question, { QuestionValues } from "models/Question";
import QuestionBase from "models/QuestionBase";
import QuestionFreetext, {
  QuestionFreetextValues,
} from "models/QuestionFreetext";

import { groupBy, meanBy } from "lodash-es";
import QuestionList from "models/QuestionList";

export type ProfileData = {
  name: string;
  introKey: string;
  introData: IntroText[];
  questions: Array<QuestionBase>;
};

/**
 * Map the Firebase JSON structure to the expected QuestionBase implementations.
 *
 * @param survey the question list received from the backend
 * @returns a list of questions for the given survey profile
 *
 * @todo improve readabilty by mapping JSON structure to class (e.g. JsonProperty annotations?)
 */
export function mapSurveyProfileSchemaToQuestions(
  survey: SurveyProfileSchema
): Array<QuestionBase> {
  const questions: QuestionBase[] = [];

  survey.questions.forEach((q: SurveyProfileSchemaQuestion) => {
    let question;
    // All questions must have these base values
    const base_values = {
      id: q.id,
      key: q.key,
      text: q.text,
      type: q.type,
      answer: q.answer,
    };

    if (["single-select"].includes(q.type)) {
      const values = {
        ...base_values,
        category: q.category,
        answers: q.answers?.map(
          ({ text, value, question_id }) =>
            new Answer({ text, value, question_id })
        ),
      } as unknown as QuestionValues;

      question = new Question(values);
    } else if (["deep-select", "deep-select-item"].includes(q.type)) {
      const values = {
        ...base_values,
        category: q.category,
        answers: q.answers?.map(
          ({ text, value, question_id }) =>
            new Answer({ text, value, question_id })
        ),
      } as unknown as QuestionValues;

      question = new QuestionList(values);
    } else if (q.type === "text") {
      const values = {
        ...base_values,
        clarification: q.clarification,
      } as unknown as QuestionFreetextValues;

      question = new QuestionFreetext(values);
    }

    // If question exists, then the given `type` was valid
    if (question) {
      // Hydrate the answer if it already exists on the backend
      if (typeof q.answer !== "undefined") {
        question.setAnswer(q.answer);
      }

      questions.push(question);
    }
  });

  questions
    .filter((q) => q.type.startsWith("deep-select"))
    .forEach((q: QuestionBase) => {
      const question = q as Question;
      question.answers.forEach((answer) => {
        const next_question = questions.find(
          (a_q) => a_q.id === answer.question_id
        );
        if (next_question) {
          answer.setQuestion(next_question);
        }
      });
    });

  return questions.filter((q) => q.type !== "deep-select-item");
}

async function getProfile(
  url: string,
  user_data: UserDataSchema
): Promise<ProfileData> {
  // A single survey profile schema
  const data = await getSurvey(url, user_data);
  const questions = mapSurveyProfileSchemaToQuestions(data);

  return {
    name: `${data.name}_${Math.random()}`,
    introData: data.introduction,
    introKey: data.introduction_key,
    questions,
  };
}

export async function getProfiles(urls: string[]): Promise<ProfileData[]> {
  // Get the user data once and share with profile creation
  const user_data = await getUserData();

  const promises = urls.map(async (url) => {
    return getProfile(url, user_data);
  });

  return Promise.all(promises);
}

export async function getAssessments(urls: string[]) {
  // Get the user data once and share with profile creation
  const user_data = await getUserData();

  const promises = urls.map(async (url) => {
    return getSurvey(url, user_data);
  });

  return Promise.all(promises);
}

export function prepareAssessment(data: SurveyProfileSchema) {
  const questions = mapSurveyProfileSchemaToQuestions(data);

  return {
    name: `${data.name}_${Math.random()}`,
    introData: data.introduction,
    introKey: data.introduction_key,
    questions,
  };
}

export function calculateMeanValues(profiles: ProfileData[]) {
  // Flattened array of questions with a category
  const category_questions = profiles.reduce((arr, profile) => {
    // Use only questions needed for the report. These differ from other by having a category attribute.
    const questions_with_category = profile.questions.filter(
      (q) => q instanceof Question && (q as Question).category
    );

    return [...arr, ...questions_with_category];
  }, [] as QuestionBase[]);

  // Group categories to facilitate mean calculation
  const categories = groupBy(category_questions, "category");

  // calculate mean value for each category
  const mean_values = Object.entries(categories).reduce(
    (o, [category, questions]) => {
      return {
        ...o,
        [category]: meanBy(questions, (q) => Number(q.answer!.value)),
      };
    },
    {}
  );

  return mean_values;
}
