import React, { ReactFragment, ReactNode } from "react";

import { Box, Grid, Typography, alpha, useMediaQuery } from "@mui/material";

interface SectionContainerProps {
  title: string;
  children: ReactNode | ReactFragment;
}

export default function SectionContainer({
  title,
  children,
}: SectionContainerProps) {
  const print = useMediaQuery("print");

  return (
    <Box sx={{ color: "primary.main" }}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "1.75em",
          mt: [4, 4, 7],
          mb: [3, 3, 6],
        }}
      >
        {title}
      </Typography>
      <Grid
        container
        columns={5}
        sx={{ color: (theme) => alpha(theme.palette.primary.main, 0.7) }}
      >
        {print && (
          <Grid item xs={5} md={5}>
            {children}
          </Grid>
        )}
        {!print && (
          <>
            <Grid item xs={0} md={1} />
            <Grid item xs={5} md={4}>
              {children}
            </Grid>
          </>
        )}
      </Grid>
      {/* <Box sx={{ ml: { xs: 0, md: 28 } }}>
                {children}
            </Box> */}
    </Box>
  );
}
