import React from "react";

import Question from "models/Question";

import QuestionFreetext from "models/QuestionFreetext";

import QuestionBase from "models/QuestionBase";
import debounce from "lodash.debounce";
import QuestionList from "models/QuestionList";
import QuestionTypeFreetext from "./types/QuestionTypeFreetext";

import QuestionTypeList from "./types/QuestionTypeList";

interface QuestionTypeProps {
  question: QuestionBase;
  stepForward: () => void;
  // onSelect: (value: string) => void;
}

export default function QuestionTypeManager({
  question,
  stepForward,
}: QuestionTypeProps) {
  const debouncedChangeHandler = debounce(stepForward, 1000);

  // If the question is of type list
  if (question instanceof Question || question instanceof QuestionList) {
    // Go to next question after the user has selected an answer from the list
    return (
      <QuestionTypeList
        question={question as Question}
        onSelect={debouncedChangeHandler}
      />
    );
  }

  // If the question is of type freetext
  if (question instanceof QuestionFreetext) {
    // Don't auto-advance when entering text
    return <QuestionTypeFreetext question={question as QuestionFreetext} />;
  }

  return <></>;
}
