import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import backend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";

// import en from "./locales/en.json";
// import de from "./locales/de.json";

// const resources = await import(`./locales/${langKey}.json`);

type Lang = {
  name: string;
  code: string;
};

export const supportedLngs: Lang[] = [
  { code: "en", name: "English" },
  { code: "de", name: "Deutsch" },
  { code: "fr", name: "Français" },
  { code: "es", name: "Español" },
  { code: "it", name: "Italiano" },
  { code: "pt-BR", name: "Português (Brasil)" },
  { code: "pt-PT", name: "Português (Portugal)" },
  { code: "zh", name: "中文" },
  { code: "ja", name: "日本語" },
  { code: "sv", name: "Svenska" },
  { code: "pl", name: "Polski" },
];

const useLocalLanguageAssets = resourcesToBackend(
  (language, namespace, callback) => {
    import(`./locales/${language}/${namespace}.json`)
      .then((resources) => {
        callback(null, resources);
      })
      .catch((error) => {
        callback(error, null);
      });
  }
);

/**
 * @see https://www.i18next.com/overview/configuration-options
 */
i18n
  .use(LanguageDetector)
  //   .use(backend)
  .use(useLocalLanguageAssets)
  .use(initReactI18next)
  .init({
    // lng: "en",
    // nonExplicitSupportedLngs: true,
    fallbackLng: "en",
    load: "currentOnly",
    supportedLngs: supportedLngs.map((l) => l.code),
    cleanCode: true,
    // debug: true,
    // interpolation: {
    //   // not needed for react
    //   escapeValue: false,
    // },
  });

export default i18n;
