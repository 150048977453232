import React, { ReactNode, ReactFragment } from "react";
import { Box, keyframes, SxProps, Theme } from "@mui/material";

const hidden = keyframes`
    0% {
        opacity: 1;
        visibility: visible;
        pointer-events: none;
    }
    
    90% {
        opacity: 0;
        visibility: visible;
        pointer-events: none;
    }
    100% {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
`;

interface MainContainerProps {
  sx?: SxProps<Theme>;
  progress?: ReactNode | ReactFragment;
  navButtons?: ReactNode | ReactFragment;
  nextButton?: ReactNode | ReactFragment;
  children: ReactNode | ReactFragment;
  hideNextButton?: boolean;
}

function MainContainer(props: MainContainerProps) {
  const common = {
    display: "flex",
    maxWidth: "lg",
    width: "100%",
    px: 1,
    zIndex: 2,
    backgroundColor: "background.default",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        ...props.sx,
      }}
    >
      {/* <Box sx={{ position: 'sticky', width: '100%', top: 0, zIndex: 10 }}> */}
      <Box sx={{ position: "fixed", left: 0, right: 0, top: 0, zIndex: 10 }}>
        {props.progress && (
          <Box
            sx={{
              mx: "auto",
              pt: 2,
              pb: 1.5,
              "> div": {
                mr: 1.5,
              },
              "> div:last-child": {
                mr: "0",
              },
              ...common,
            }}
          >
            {props.progress}
          </Box>
        )}
        {props.navButtons && (
          <Box sx={{ mx: "auto", justifyContent: "space-between", ...common }}>
            {props.navButtons}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {props.children}
      </Box>

      <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          pb: "env(safe-area-inset-bottom, 0)",
          zIndex: 10,
          // Small trick to animate the opacity, but still keep the visibility of the component as visible.
          // When the animation is finished we set the visibility to hidden so that the component is not tabbable anymore.
          animation: props.hideNextButton ? `${hidden} 0.3s ease` : null,
          // This is important to keep the hidden state
          animationFillMode: "forwards",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "sm",
            px: 3,
            pb: 3,
            mx: "auto",
            transform: "translate3d(0,0,0)",
          }}
        >
          {props.nextButton}
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(MainContainer);
