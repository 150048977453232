import * as React from "react";
import { Routes, Route } from "react-router-dom";

import "styles/app.css";

// const Survey = React.lazy(() => import("components/Survey"));
// const Report = React.lazy(() => import("components/report/Report"));

import Survey from "components/Survey";
import Report from "components/report/Report";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Survey />} />
      <Route path="/report/:token" element={<Report />} />
    </Routes>
  );
}
