import React from "react";

import { Grid, ButtonProps } from "@mui/material";

import NextButton from "components/base/NextButton";
import WelcomeCardContainer, {
  WelcomeCardContent,
} from "components/base/WelcomeCardContainer";

import { Trans, useTranslation } from "react-i18next";

function PrivacyPolicySlide(props: ButtonProps) {
  const { t } = useTranslation();

  return (
    <WelcomeCardContainer sx={{ maxWidth: "sm", ...props.sx }}>
      <Grid container>
        <Grid item xs={12} sm={10} md={10}>
          <WelcomeCardContent
            title={t("slides.privacy_policy.title")}
            subtitle={
              <Trans i18nKey="slides.privacy_policy.text">
                The assessment is fully anonymous, you will not be asked your
                email address, your name or contact details, and only you will
                be seeing your personal results at the end. By continuing to use
                this service, you agree to our
                <a
                  href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                  target="_blank"
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  privacy policy
                </a>
              </Trans>
            }
          />
        </Grid>
      </Grid>
      <NextButton
        onClick={props.onClick}
        sx={{
          px: 8,
          width: "auto",
          fontWeight: 700,
          mb: { xs: 2, md: 0 },
        }}
      >
        {t("confirmation.i_agree")}
      </NextButton>
    </WelcomeCardContainer>
  );
}

export default PrivacyPolicySlide;
