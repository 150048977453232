import React from "react";
import { useTranslation } from "react-i18next";

import Mbi from "models/Mbi";

import { Box, Grid, Typography } from "@mui/material";

import RATING from "values/rating";
import ColorValues from "values/colors";

import RatingLegend from "./base/RatingLegend";
import CategoryCard from "./base/CategoryCard";

interface SummaryMbiProps {
  profile: Mbi;
}

export default function SummaryMbi({ profile }: SummaryMbiProps) {
  const { t } = useTranslation();
  const type = profile.getType();
  const rating = profile.getRating();

  const ratings_text: Record<RATING, string> = {
    [RATING.BAD]: t(`report.rating_percentile.${RATING.BAD}`),
    [RATING.AVERAGE]: t(`report.rating_percentile.${RATING.AVERAGE}`),
    [RATING.GOOD]: t(`report.rating_percentile.${RATING.GOOD}`),
  };

  return (
    <Box>
      <Typography
        sx={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "1.75em",
          color: "primary.main",
          my: 3,
        }}
      >
        {t("report.profile")}:{" "}
        <span style={{ color: ColorValues[rating] }}>
          {t(`report.profile_type.${type}`)}
        </span>
      </Typography>
      <Grid container spacing={2} columns={{ xs: 1, md: 3 }}>
        {profile.getCategories().map((category) => (
          <Grid item xs={1} key={category}>
            <CategoryCard
              total={6}
              title={t(
                `assessments.mbi.dimension.${category.replace(" ", "_")}`
              )}
              progress={profile.getCategoryProgress(category)}
              rating={profile.getCategoryRating(category)}
              value={profile.getMeanValue(category)}
            />
          </Grid>
        ))}
      </Grid>
      <RatingLegend ratings_text={ratings_text} />

      <Typography
        sx={{
          my: 3,
          fontWeight: 500,
          fontSize: "0.95em",
          color: "primary.main",
        }}
      >
        {t(`report.profile_description.${type}`)}
      </Typography>
    </Box>
  );
}
