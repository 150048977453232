import { BM_VALUES } from "values/bm";
import RATING from "values/rating";

// import { pick } from "lodash-es";

export interface ProfileProps {
  // Cant use generics, without a factory?
  mean_values: Record<string, number>;
}

/**
 *
 * @todo rename to Assessment
 */
export default abstract class Profile<T extends string> {
  private mean_values: Record<T, number>;

  constructor({ mean_values }: ProfileProps) {
    this.mean_values = mean_values;
    // The passed values will possibly contain all available categories
    // Filter mean_values so that only contains categories in the implementing class
    // this.mean_values = pick(
    //   mean_values,
    //   ...Object.values(this.getCategories())
    // );
  }

  abstract getBMValues(category: T): BM_VALUES;

  abstract calculateRating(mean: number, category: T): RATING;

  abstract getCategories(): Array<T>;

  isValid() {
    return this.getCategories().every(
      (category) => typeof this.getMeanValue(category) !== "undefined"
    );
  }

  getZValues(): Array<number> {
    return this.getCategories().map((category) =>
      this.calculateZ(this.getMeanValue(category), category)
    );
  }

  getZValuesMap() {
    return this.getCategories().map((category) => {
      return {
        [category]: this.calculateZ(this.getMeanValue(category), category),
      };
    });
  }

  getCategoryRatings(): Record<T, string> {
    return Object.fromEntries(
      this.getCategories().map((category) => [
        category,
        this.calculateRating(this.getMeanValue(category), category),
      ])
    ) as Record<T, string>;
  }

  getCategoryRating(category: T): RATING {
    return this.calculateRating(this.getMeanValue(category), category);
  }

  getCategoryProgress(category: T): number {
    const mean = this.getMeanValue(category);

    return this.calculateZProgress(mean, category);
  }

  getMeanValue(category: T): number {
    // This shouldn't happen, but just to be sure...
    if (!this.getCategories().includes(category)) {
      throw new Error(
        `Category ${category} does not belong to ${this.constructor.name}`
      );
    }

    return this.mean_values[category];
  }

  calculateZ(mean: number, category: T) {
    const { AVERAGE, STDEV } = this.getBMValues(category);

    const z = (mean - AVERAGE) / STDEV;

    return Math.round(z * 1000) / 1000;
  }

  calculateZProgress(mean: number, category: T) {
    const z = this.calculateZ(mean, category);

    const percent = Profile.calculateZPercent(z);

    return percent || 0;
  }

  static calculateZPercent(z: number) {
    // z == number of standard deviations from the mean

    // if z is greater than 6.5 standard deviations from the mean the
    // number of significant digits will be outside of a reasonable range

    if (z < -6.5) {
      return 0.0;
    }

    if (z > 6.5) {
      return 1.0;
    }

    let factK = 1;
    let sum = 0;
    let term = 1;
    let k = 0;
    const loopStop = Math.exp(-23);

    while (Math.abs(term) > loopStop) {
      term =
        (((0.3989422804 * (-1) ** k * z ** k) / (2 * k + 1) / 2 ** k) *
          z ** (k + 1)) /
        factK;

      sum += term;
      k += 1;
      factK *= k;
    }

    sum += 0.5;

    return Math.round(sum * 100);
  }
}
