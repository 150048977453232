enum PROFILE_TYPE {
  ENGAGED = "engaged",
  INEFFECTIVE = "ineffective",
  OVEREXTENDED = "overextended",
  DISENGAGED = "disengaged",
  BURNOUT = "burnout",
  ERROR = "error",
}

export default PROFILE_TYPE;
