import React, { useState } from "react";
import { useTheme, Box, lighten, ButtonBase } from "@mui/material";

import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import type { QuestionTypeProps } from "./QuestionTypeList";

export default function QuestionTypeRadioButton({
  question,
  onSelect,
  blinkAnimation,
}: //   sx,
QuestionTypeProps) {
  const theme = useTheme();

  const initialState = question.answer
    ? question.answers.findIndex((a) => a.value === question.answer?.value)
    : undefined;

  const [activeItem, setActiveItem] = useState(initialState);

  const toggleIndex = (index: number) => {
    if (index < 0) return;

    setActiveItem(index);

    const answer = question.answers[index];
    question.setAnswer(answer.value);

    onSelect(question);
  };

  return (
    <Box sx={{ mt: 3, display: "grid", gap: 1 }}>
      {question.answers.map((answer, index) => (
        <div key={index} onClick={() => toggleIndex(index)}>
          <ButtonBase
            sx={{
              borderRadius: 1,
              backgroundColor:
                index === activeItem
                  ? lighten(theme.palette.secondary.light, 0.95)
                  : "white",
              boxShadow: index === activeItem ? 6 : 2,
              typography: "subtitle2",
              fontFamily: "inherit",
              width: "100%",
              justifyContent: "flex-start",
              textAlign: "left",
              transition: "box-shadow 0.25s linear",
              p: 1,
              m: 0,
              cursor: "pointer",
              animation: activeItem === index ? blinkAnimation : undefined,
              "&:focus-within": {
                boxShadow: 6,
                outline: `3px solid ${theme.palette.primary.light}`,
              },
            }}
          >
            <Box
              sx={{
                p: { xs: 0.5, md: 1 },
                mr: 1,
                color: "rgb(0 0 0 / 54%)",
                display: "inline-flex",
              }}
            >
              {index === activeItem && <RadioButtonChecked color="secondary" />}
              {index !== activeItem && <RadioButtonUnchecked />}
            </Box>
            {answer.text}
          </ButtonBase>
        </div>
      ))}
    </Box>
  );
}
