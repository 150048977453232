import React, { FC } from "react";

import { Box, SxProps, Theme } from "@mui/material";

interface ContentContainerProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

const ContentContainer: FC<ContentContainerProps> = (props) => {
  const { sx, children, ...rest } = props;
  return (
    <Box
      sx={{
        flex: 1,
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        px: 3,
        mt: 9,
        pb: 6,
        // The larger the device the more the content will move towards the center
        pt: { xs: 3, sm: "calc(21vh - 10%)" },

        ...sx,
      }}
      maxWidth="sm"
      width="100%"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ContentContainer;
