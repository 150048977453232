import React from "react";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SectionContainer from "./base/SectionContainer";

export default function SectionBurnout() {
  const { t } = useTranslation();

  const texts = t("report.burnout.what_is", {
    returnObjects: true,
  }) as string[];

  return (
    <SectionContainer title={t("report.burnout.about")}>
      {texts.map((text) => (
        <Typography key={text} paragraph>
          {text}
        </Typography>
      ))}
    </SectionContainer>
  );
}
