import React, { useState } from "react";

import QuestionList from "models/QuestionList";

import Question from "models/Question";
// import { Typography } from "@mui/material";
import QuestionTypeAutocomplete from "./QuestionTypeAutocomplete";
import type { QuestionTypeProps } from "./QuestionTypeList";

export default function QuestionTypeDeepList({
  question,
  onSelect,
  blinkAnimation,
}: QuestionTypeProps) {
  const active_questions = [question];
  let current_question = question;

  while (current_question.answer && current_question.answer?.question) {
    current_question = current_question.answer.question as
      | Question
      | QuestionList;

    active_questions.push(current_question);
  }

  const [question_list, setQuestions] = useState(active_questions);

  const onQuestionAnswered = (selected: Question | QuestionList) => {
    // With the current index
    const current_question_index = question_list.indexOf(selected);

    // We can get all the child questions that have been answered
    question_list.slice(current_question_index + 1).forEach((q) => {
      // And delete their answers
      q.deleteAnswer();
    });

    // If the question has a next question, we can add it to the list
    if (selected.answer?.question) {
      const next_question = selected.answer?.question as QuestionList;

      // And then we can remove all the child questions that have been answered
      // and add the new question
      setQuestions([
        ...question_list.slice(0, current_question_index + 1),
        next_question,
      ]);
    } else {
      // Remove any questions that could have been added after this level
      setQuestions(question_list.slice(0, current_question_index + 1));
      // If there are no further questions we can move forward in the question slider
      onSelect(selected);
    }
  };

  return (
    <>
      {question_list.map((item, index) => {
        return (
          <QuestionTypeAutocomplete
            key={item.id}
            question={item}
            onSelect={onQuestionAnswered}
            blinkAnimation={blinkAnimation}
            showLabel={index > 0}
          />
        );
      })}
    </>
  );
}
