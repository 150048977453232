import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  BoxProps,
} from "@mui/material";

import { addDays, endOfDay } from "date-fns";

import { getUser, removeUser } from "database/firestore";

import { PendingActions } from "@mui/icons-material";
import formatDistanceToNow from "utils/date";
import { Trans, useTranslation } from "react-i18next";

function SessionText(props: BoxProps) {
  return (
    <Trans i18nKey="slides.session.text">
      If you wish to restart your survey you can
      <Box
        component={"span"}
        sx={{
          display: "inline",
          textDecoration: "underline",
          ":hover": {
            color: (theme) => theme.palette.error.main,
            cursor: "pointer",
          },
        }}
        onClick={props.onClick}
      >
        clear all responses
      </Box>
    </Trans>
  );
}

function ResetForm() {
  const { t } = useTranslation();
  const [confirmDialogOpened, setConfirmDialogOpen] = useState(false);

  return (
    <>
      <br />
      <br />
      <Typography
        component="span"
        sx={{ lineHeight: 1.75, mt: 2, whiteSpace: "pre-wrap" }}
      >
        <SessionText onClick={() => setConfirmDialogOpen(true)} />
      </Typography>

      <Dialog
        open={confirmDialogOpened}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogContent>
          <Typography variant="h6">
            {t("slides.session.do_you_wish_to_restart")}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            flexWrap: "wrap",
          }}
        >
          <Button onClick={() => setConfirmDialogOpen(false)} autoFocus>
            {t("common.cancel")}
          </Button>
          <Button
            color="error"
            variant="contained"
            disableElevation={true}
            onClick={() => {
              removeUser();
              window.location.reload();
            }}
          >
            {t("slides.session.delete_and_restart")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function CurrentSessionDialog() {
  const { t, i18n } = useTranslation();
  const [expires_on, setExpireOn] = useState<string>();
  const [dialogOpened, setDialogOpen] = useState(false);

  useEffect(() => {
    // Calculate when the survey will expire
    getUser().then((user) => {
      const created_at = new Date(user.metadata.creationTime as string);
      const end_of_next_day = endOfDay(addDays(created_at, 1));

      const distance_to_now = formatDistanceToNow(
        end_of_next_day,
        i18n.language
      );

      setExpireOn(distance_to_now);
    });
  }, []);

  return (
    <>
      {/* Icon button that lives in the app bar */}
      <IconButton sx={{ color: "white" }} onClick={() => setDialogOpen(true)}>
        <PendingActions />
      </IconButton>
      {/* Dialog will inform the user what the is state of his session */}
      <Dialog open={dialogOpened} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{t("slides.session.title")}</DialogTitle>
        <DialogContent sx={{ typography: "subtitle1", opacity: 1 }}>
          <DialogContentText>
            {/* The session will expire  */}
            {t("slides.session.will_expire_on", {
              relative_date: expires_on,
            })}
            {/* Allow the user to manage remove all local data */}
            <ResetForm />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
