import React from "react";

import { West } from "@mui/icons-material";
import { Button, ButtonProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function PreviousButton(props: ButtonProps) {
  const { t } = useTranslation();
  return (
    <Button
      variant="text"
      startIcon={<West />}
      sx={{
        color: "gray",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "transparent",
      }}
      {...props}
    >
      <Typography
        sx={{ fontSize: "0.7rem", fontWeight: "bold", letterSpacing: "0.25em" }}
      >
        {t("common.previous").toUpperCase()}
      </Typography>
    </Button>
  );
}

export default React.memo(PreviousButton);
