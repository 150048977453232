import React from "react";
import { useTranslation } from "react-i18next";

import { Circle } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";

import Aws from "models/Aws";

import RATING from "values/rating";
import ColorValues from "values/colors";
import CategoryCard from "./base/CategoryCard";
import RatingLegend from "./base/RatingLegend";

interface SummaryAwsProps {
  profile: Aws;
}

export default function SummaryAws({ profile }: SummaryAwsProps) {
  const { t } = useTranslation();

  const ratings_text: Record<RATING, string> = {
    [RATING.BAD]: t(`report.rating_alignment.${RATING.BAD}`),
    [RATING.AVERAGE]: t(`report.rating_alignment.${RATING.AVERAGE}`),
    [RATING.GOOD]: t(`report.rating_alignment.${RATING.GOOD}`),
  };

  function getCategoryTranslation(category: string) {
    return t(`assessments.aws.area.${category.replace(" ", "_")}`);
  }

  return (
    <Box>
      {/* <Box sx={{ overflow: 'scroll' }}> */}
      <Typography
        sx={{
          textTransform: "capitalize",
          fontWeight: 600,
          fontSize: "1.75em",
          color: "primary.main",
          my: 3,
        }}
      >
        {t("report.worklife_areas")}
      </Typography>
      <Typography
        sx={{
          my: 3,
          fontWeight: 500,
          fontSize: "0.95em",
          color: "primary.main",
        }}
      >
        {t("report.worklife_description")}
      </Typography>
      <Grid container spacing={2} columns={{ xs: 1, md: 3 }}>
        {profile.getCategories().map((category) => (
          <Grid item xs={1} key={category}>
            <CategoryCard
              total={5}
              title={getCategoryTranslation(category)}
              progress={profile.getCategoryProgress(category)}
              rating={profile.getCategoryRating(category)}
              value={profile.getMeanValue(category)}
            />
          </Grid>
        ))}
      </Grid>
      <RatingLegend ratings_text={ratings_text} />

      <Typography
        sx={{
          mb: 3,
          fontWeight: 500,
          fontSize: "0.95em",
          color: "primary.main",
        }}
      />

      {profile.getCategories().map((category) => {
        const rating = profile.getCategoryRating(category);

        return (
          <Typography
            key={category}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 1,
              mb: 2,
              color: "primary.main",
            }}
          >
            <Circle sx={{ color: ColorValues[rating], fontSize: 8, mt: 1 }} />
            <span>
              <span style={{ fontWeight: 700, textTransform: "capitalize" }}>
                {getCategoryTranslation(category)}:
              </span>
              &nbsp;
              {t(`report.worklife.${category}.${rating}`)}
            </span>
          </Typography>
        );
      })}
    </Box>
  );
}
