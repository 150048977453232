import React from "react";
import { keyframes, SxProps, Theme, useTheme } from "@mui/material";

import Question from "models/Question";

import QuestionList from "models/QuestionList";
import QuestionTypeDeepList from "./QuestionTypeDeepList";
import QuestionTypeRadioButton from "./QuestionTypeRadioButton";
import QuestionTypeAutocomplete from "./QuestionTypeAutocomplete";

const blink = () => keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }
`;

export interface QuestionTypeProps {
  question: Question | QuestionList;
  onSelect: (question: Question | QuestionList) => void;
  //   onSelect: (value: string) => void;
  blinkAnimation?: string;
  sx?: SxProps<Theme>;
  forceAutocomplete?: boolean;
}

export default function QuestionTypeList({
  question,
  onSelect,
  forceAutocomplete,
}: QuestionTypeProps) {
  const { transitions } = useTheme();

  const blinkAnimation = `${blink()} 0.25s ${transitions.easing.sharp} 0s 2`;

  const is_short_list = !forceAutocomplete && question.answers.length <= 7;

  if (question.type === "deep-select") {
    return (
      <QuestionTypeDeepList
        question={question}
        onSelect={onSelect}
        blinkAnimation={blinkAnimation}
      />
    );
  }

  return (
    <>
      {is_short_list && (
        <QuestionTypeRadioButton
          question={question}
          onSelect={onSelect}
          blinkAnimation={blinkAnimation}
        />
      )}

      {!is_short_list && (
        <QuestionTypeAutocomplete
          question={question}
          onSelect={onSelect}
          blinkAnimation={blinkAnimation}
        />
      )}
    </>
  );
}
