import type QuestionBase from "./QuestionBase";

export interface AnswerValues {
  key?: string;
  text: string;
  value: string;
  question?: QuestionBase;
  question_id?: string;
}

export default class Answer implements AnswerValues {
  key?: string;

  text: string;

  value: string;

  question?: QuestionBase;

  question_id?: string;

  constructor(values: AnswerValues) {
    this.key = values.key;
    this.text = values.text;
    this.value = values.value;

    this.question_id = values.question_id;
  }

  setQuestion(question: QuestionBase) {
    this.question = question;
  }
}
