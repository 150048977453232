import React from "react";

import { Box, Theme, SxProps } from "@mui/material";

import useStepper, { StepperProps } from "hooks/useStepper";

import MainContainer from "components/base/MainContainer";

import WelcomeSlide from "./WelcomeSlide";
import PrivacyPolicySlide from "./PrivacyPolicySlide";

interface WelcomeSlideProps extends StepperProps {
  sx?: SxProps<Theme>;
}

function IntroductionSlides(props: WelcomeSlideProps) {
  const { stepForward, displayStep } = useStepper(0, 2, props);

  return (
    <>
      <MainContainer sx={{ ...props.sx }}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <WelcomeSlide sx={{ ...displayStep(0) }} onClick={stepForward} />
          <PrivacyPolicySlide
            sx={{ ...displayStep(1) }}
            onClick={stepForward}
          />
        </Box>
      </MainContainer>
    </>
  );
}

export default IntroductionSlides;
