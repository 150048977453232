import React from "react";
import { Typography, TextField } from "@mui/material";

import QuestionFreetext from "models/QuestionFreetext";

interface QuestionTypeProps {
  question: QuestionFreetext;
  //   onSelect?: (value: string) => void;
}

export default function QuestionTypeFreetext({ question }: QuestionTypeProps) {
  return (
    <>
      <Typography variant="subtitle1" color="primary.main">
        {question.clarification}
      </Typography>

      <TextField
        sx={{ mt: 3 }}
        name={question.clarification}
        label="Your answer"
        multiline
        variant="filled"
        defaultValue={question.answer?.value || ""}
        minRows={4}
        maxRows={10}
        onChange={(evt) => question.setAnswer(evt.target.value)}
      />
    </>
  );
}
