import React, { FC } from "react";

import { ReactComponent as Left } from "assets/button_shadow_start.svg";
import { ReactComponent as Right } from "assets/button_shadow_end.svg";

import { Box, Button, ButtonProps } from "@mui/material";

const NextButton: FC<ButtonProps> = ({ children, ...props }) => {
  const { sx, ...rest } = props;

  return (
    <Button
      size="large"
      color="secondary"
      variant="contained"
      sx={{
        position: "relative",
        fontWeight: 600,
        width: "100%",
        transition: "opacity 0.2s ease-in-out",
        ...sx,
      }}
      {...rest}
    >
      <Box sx={{ position: "absolute", top: 0, left: 0, height: "100%" }}>
        <Left style={{ height: "100%" }} />
      </Box>
      <div style={{ zIndex: 1 }}>{children}</div>
      <Box sx={{ position: "absolute", top: 0, right: 0, height: "100%" }}>
        <Right style={{ height: "100%" }} />
      </Box>
    </Button>
  );
};

export default NextButton;
