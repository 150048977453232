import { merge } from "lodash-es";
import Answer from "./Answer";
import QuestionBase, { QuestionBaseValues } from "./QuestionBase";

export interface QuestionListValues extends QuestionBaseValues {
  answers: Answer[];
}

export default class QuestionList
  extends QuestionBase
  implements QuestionListValues
{
  answers: Answer[];

  constructor(values: QuestionListValues) {
    super(values);

    this.answers = values.answers;
  }

  private checkIsAnsweredRecursively(question: QuestionBase): boolean {
    if (question.answer !== undefined) {
      if (question.answer.question) {
        return this.checkIsAnsweredRecursively(question.answer.question);
      }

      return true;
    }

    return false;
  }

  setAnswer(value: string) {
    this.answer = this.answers.find((a) => a.value === value);
  }

  isAnswered(): boolean {
    return this.checkIsAnsweredRecursively(this);
  }
}

function getAnswersRecursively(
  question?: QuestionBase
): Record<string, { question: string; answer: string }> {
  if (!question) return {};

  const as_list = question as QuestionList;

  return merge(
    {
      [as_list.id]: {
        question: question.text,
        answer: question.answer?.value,
      },
    },
    ...as_list.answers.map((answer) => getAnswersRecursively(answer.question))
  );
}

export function getAnswers(
  question: QuestionBase
): Record<string, { question: string; answer: string }> {
  return getAnswersRecursively(question);
}
