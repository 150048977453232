import Answer from "./Answer";

export interface QuestionBaseValues {
  id: string;
  key: string;
  text: string;
  type: string;
  answer?: Answer;
}

export default abstract class QuestionBase implements QuestionBaseValues {
  id: string;

  key: string;

  text: string;

  type: string;

  answer?: Answer;

  listeners: Array<(question: QuestionBase) => void>;

  constructor(values: QuestionBaseValues) {
    this.id = values.id;
    this.key = values.key;
    this.text = values.text;
    this.type = values.type;

    this.listeners = [];
  }

  abstract setAnswer(value: string): void;
  abstract isAnswered(): boolean;

  notify() {
    this.listeners.forEach((fn) => fn(this));
  }

  addListener = (fn: (q: QuestionBase) => void) => {
    this.listeners.push(fn);
  };

  deleteAnswer() {
    this.answer = undefined;
  }
}
