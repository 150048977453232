import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  SxProps,
  Theme,
  keyframes,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import useStepper, { StepperProps } from "hooks/useStepper";

import SwipeableViews from "react-swipeable-views";

import NextButton from "components/base/NextButton";
import OverviewButton from "components/base/OverviewButton";
import PreviousButton from "components/base/PreviousButton";

import MainContainer from "components/base/MainContainer";
import ContentContainer from "components/base/ContentContainer";

import { saveQuestion } from "database/firestore";

import QuestionBase from "models/QuestionBase";

import QuestionFreetext from "models/QuestionFreetext";
import CreateReportDialog from "./CreateReportDialog";
import QuestionTypeManager from "./QuestionTypeManager";
import QuestionOverviewDialog from "./QuestionOverviewDialog";

const enter = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const hidden = keyframes`
    0% {
        opacity: 1;
        visibility: visible;
    }
    
    90% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
`;

// const visible = keyframes`
//     0% {
//         opacity: 0;
//         visibility: hidden;
//     }
//     10% {
//         opacity: 0;
//         visibility: visible;
//     }
//     100% {
//         opacity: 1;
//         visibility: visible;
//     }
// `;

interface QuestionSliderProps extends StepperProps {
  questions: Array<QuestionBase>;
  sx?: SxProps<Theme>;
}

/**
 * The QuestionSlider is the main component for displayiing the assessment questions.
 * It renders a container with a non-swipable carousel of questions. Questions can be skipped.
 * At the end of the assessment, the user will be prompted fill any missing questions
 * and it will jump to the first unanswered item.
 * On the last question, the user will be prompted to create a report.
 *
 * @param props Will contain the questions to be displayed.
 * @returns The QuestionSlider component.
 */
function QuestionSlider(props: QuestionSliderProps) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState<QuestionBase[]>([]);
  const [isCurrentQuestionAnswered, setIsCurrentQuestionAnswered] =
    useState(false);
  const [has_missing_questions, setHasMissingQuestions] = useState(true);
  const [isOpenCreateReportDialog, setOpenCreateReportDialog] = useState(false);
  const [snackbar, setSnackbarState] = useState(false);

  const { activeStep, stepForward, stepBack, setStep } = useStepper(
    0,
    questions.length,
    props
  );

  useEffect(() => {
    // Since we are using class instances, we need to manually listen for changes
    // to the answer property. We register a listener for all questions. When the
    // answer changes, we notify the component to re-render.
    // This is a workaround for now, but we should refactor the QuestionBase class
    // to use a state management library (Redux) or use as a regular type.
    // I'm sorry about this :(
    props.questions.forEach((question) => {
      // Lets just listen to freetext questions for now
      // All other questions are not necessary since they move forward automatically
      // when the answer is changed.
      if (question instanceof QuestionFreetext) {
        question.addListener((q) => {
          // we assume that when the event is fired, it is the current question
          // that has been answered. This is not ideal, but it works for now.
          setIsCurrentQuestionAnswered(q.isAnswered());
        });
      }
    });
    setQuestions(props.questions);
  }, [props.questions.map(({ id }) => id).join("-")]);

  // Check if question is answered
  // I'm sorry about this :(
  useEffect(() => {
    if (questions.length > 0) {
      const currentQuestion = questions[activeStep];
      setIsCurrentQuestionAnswered(currentQuestion.isAnswered());
    }
  }, [questions[activeStep]]);

  const handleStepForward = async () => {
    // Don't allow the user to move forward if the current question is not answered
    if (!questions[activeStep].isAnswered()) return;

    // Answer might be empty
    saveQuestion(questions[activeStep]);

    let is_last_answered_question = true;
    const missing_questions = questions.some((q) => !q.answer);

    for (let index = activeStep; index < questions.length; index += 1) {
      is_last_answered_question = questions[index].isAnswered();

      if (!is_last_answered_question) break;
    }
    // Update this on every question change so that we can exit early
    setHasMissingQuestions(missing_questions);

    if (!missing_questions) {
      props.onDone();
      return;
    }
    // If we are on the last step and the user clicks "next", we inform
    // that there are unanswered questions and if it wishes to navigate
    if (is_last_answered_question && missing_questions) {
      setOpenCreateReportDialog(true);
      return;
    }

    window.scrollTo(0, 0);
    stepForward();
  };

  /**
   * When the "create report" dialog opens, the user can either close the
   * dialog or click the pop-up button. This button will change depending
   * on the state of the survey.
   * If the user has answered all the questions for the assessment, a
   * subpart of the total survey, the user will be navigate to the next assessment.
   * If not, the button will skip to the first unanswered question.
   *
   * If the user has answered all questions, the action will create the report.
   */
  function createReportDialogPositiveAction() {
    setOpenCreateReportDialog(false);

    // If all questions have been answered just end the survey
    // could jump to the next survey or show the report window
    if (!has_missing_questions) {
      props.onDone();
    }
    // The user has clicked through all items, but skipped answering some
    // questions. The popup only allows the user to confirm jumping to the first
    // missing questions. We assume this on close.
    else if (has_missing_questions) {
      const first_missing_index = questions.findIndex((q) => !q.answer);

      setStep(first_missing_index);
    }
  }

  const Progress = (
    <LinearProgress
      color="primary"
      variant="determinate"
      value={(activeStep / questions.length) * 100}
      sx={{ height: 8, borderRadius: 100, flex: 1 }}
    />
  );

  const NavButtons = (
    <>
      <PreviousButton onClick={stepBack} />
      <OverviewButton onClick={() => setOpen(true)} />
    </>
  );

  return (
    <>
      <QuestionOverviewDialog
        open={open}
        questions={questions}
        onSelection={(index) => setStep(index)}
        onClose={() => setOpen(false)}
      />

      <CreateReportDialog
        open={isOpenCreateReportDialog}
        isFinished={!has_missing_questions}
        onAccept={createReportDialogPositiveAction}
        onClose={() => setOpenCreateReportDialog(false)}
      />

      <Snackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbarState(false)}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Alert
          elevation={6}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {t("errors.answer_not_saved")}
        </Alert>
      </Snackbar>

      <MainContainer
        sx={{ ...props.sx }}
        progress={Progress}
        navButtons={NavButtons}
        hideNextButton={!isCurrentQuestionAnswered}
        nextButton={<NextButton onClick={handleStepForward}>Next</NextButton>}
      >
        <Box sx={{ animation: `${enter} 0.5s ease`, flex: 1, height: "100%" }}>
          {questions.length > 0 && (
            <SwipeableViews
              key="swipe"
              id="question-container"
              disabled
              axis="x"
              index={activeStep}
              //   containerStyle={{ minHeight: "100vh", alignItems: "center" }}
              style={{
                height: "100%",
                overflowX: "unset",
              }}
            >
              {questions.map((question, index) => (
                <ContentContainer
                  key={question.id}
                  sx={{
                    // Small trick to animate the opacity of the slide animation
                    // but still keep the visibility of the component as visible.
                    // When the animation is finished we set the visibility to hidden
                    // so that the component is not tabbable anymore.
                    animation:
                      activeStep !== index ? `${hidden} 0.3s ease` : null,
                    // This is important to keep the hidden state
                    animationFillMode: "forwards",
                  }}
                >
                  <Typography
                    variant="caption"
                    color="primary.light"
                    sx={{
                      fontSize: "0.7rem",
                      fontWeight: "bold",
                      letterSpacing: "0.25em",
                      mb: 1,
                    }}
                  >
                    {t("common.question").toUpperCase()} {index + 1} /{" "}
                    {questions.length}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 700,
                      minHeight: { xs: "3em", md: "3.2em" }, // lineheight * 2
                      pr: { md: "20%" },
                      color: "primary.main",
                    }}
                  >
                    {question.text}
                  </Typography>

                  <QuestionTypeManager
                    question={question}
                    stepForward={handleStepForward}
                  />
                </ContentContainer>
              ))}
            </SwipeableViews>
          )}
        </Box>
      </MainContainer>
    </>
  );
}

export default QuestionSlider;
