import React from "react";
import { Trans } from "react-i18next";

import {
  Box,
  BoxProps,
  Theme,
  Typography,
  SxProps,
  keyframes,
} from "@mui/material";

import IntroText from "models/IntroText";

import useStepper, { StepperProps } from "hooks/useStepper";

import NextButton from "components/base/NextButton";
import PreviousButton from "components/base/PreviousButton";
import MainContainer from "components/base/MainContainer";
import ContentContainer from "components/base/ContentContainer";

const enter = keyframes`
    from {
        transform: translate3d(0,16px,0);
        opacity: 0;
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
`;

function Line(props: BoxProps) {
  const { sx, ...other } = props;

  return (
    <Box
      sx={{
        height: 8,
        flexGrow: 1,
        borderRadius: 8,
        ...sx,
      }}
      {...other}
    />
  );
}

interface IntroSliderProps extends StepperProps {
  data: IntroText[];
  sx?: SxProps<Theme>;
}

function IntroSlider(props: IntroSliderProps) {
  const { activeStep, stepForward, stepBack } = useStepper(
    0,
    props.data.length,
    props
  );

  const Progress = props.data.map(({ title }, index) => (
    <Line
      key={title}
      sx={{ bgcolor: index <= activeStep ? "primary.main" : "grey.300" }}
    />
  ));

  const Next = (
    <NextButton onClick={stepForward}>
      {props.data[activeStep] && props.data[activeStep].confirmation}
    </NextButton>
  );

  const is_kyan_content_hidden =
    process.env.REACT_APP_KYAN_CONTENT_HIDDEN?.toLowerCase() === "true";

  return (
    <MainContainer
      sx={{ ...props.sx }}
      progress={Progress}
      navButtons={<PreviousButton onClick={stepBack} />}
      nextButton={Next}
    >
      <ContentContainer>
        {props.data.length > 0 && (
          <Box key={activeStep} sx={{ animation: `${enter} 0.5s ease` }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: 700, pr: "20%", color: "primary.main" }}
            >
              {props.data[activeStep].title}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                lineHeight: 1.75,
                mt: 2,
                pb: 6,
                opacity: 0.8,
                whiteSpace: "pre-wrap",
              }}
            >
              {/* Some translations use bold text. */}
              {/* <Trans i18nKey={props.data[activeStep].text}>
                sometest.
                {!is_kyan_content_hidden && <>Text to hide</>}
                other text
              </Trans> */}
              <Trans
                i18nKey={props.data[activeStep].text}
                components={[
                  <span
                    key={activeStep}
                    style={{
                      display: is_kyan_content_hidden ? "none" : "inherit",
                    }}
                  />,
                ]}
              ></Trans>
              {/* <Trans>{props.data[activeStep].text}</Trans> */}
            </Typography>
          </Box>
        )}
      </ContentContainer>
    </MainContainer>
  );
}

export default IntroSlider;
